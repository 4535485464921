.conseil-admin-page {

  &__title{
    @apply mb-14;
  }

  &__member-list{
    @apply flex justify-between flex-wrap my-12;

    .admin-card{
      flex:1;
      min-width: 300px;
      @apply bg-white p-5 rounded-lg mr-5 mb-5;

      h3 {
        @apply text-orange;
      }

      .role{
        @apply font-semibold my-4;
      }

      .company{
        @apply font-normal;
      }
     }
  } 
}
