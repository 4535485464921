.tabs {
    @apply overflow-hidden;
    @apply w-full border-b border-darkBlue mt-5 md:mt-10;
    
    .swiper-wrapper {
        @apply flex;
    }

    .tab {
        @apply text-base md:text-2xl cursor-pointer flex gap-2;
        @apply border-b-4 border-transparent;
        @apply text-darkBlue;
        font-family: 'Poppins';

        .count {
            @apply text-xs md:text-sm bg-darkBlue text-white rounded-full self-center;
            padding-left: 0.4rem;
            padding-right: 0.4rem;

            @screen md {
                @apply px-2;
            }
        }

        .title {
            @apply whitespace-nowrap;
        }

        &.active {
            @apply  border-orange;
        }
    }
}