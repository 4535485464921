//------ Searchbar styling

.generic-searchbar{
  .geoloc-icon{
    @apply bg-darkBlue rounded-full w-10 h-10 flex justify-center items-center cursor-pointer;

    &:hover{
      img{
        transform: rotate(90deg);
      }
    }

    img{
      transition:all 0.2s ease;
      @apply w-5;
    }

    .loading {
      animation: rotating 2s linear infinite;
  }

  @keyframes rotating {
      from{
          transform: rotate(0deg);
      }
      to{
          transform: rotate(360deg);
      }
    }
  }

  input,select{
    font-family: "Poppins";
    @apply h-4 md:h-8 text-darkBlue ml-4;
    @apply text-xs md:text-base;

    &:focus-visible{
      @apply outline-none
    }

    &::placeholder {
      @apply text-darkGrey;
    }
  }

  .submit-btn {
    transition:all 0.2s ease;
    background-size: 100%;
    @apply md:mr-4 w-1 md:w-6 cursor-pointer bg-center bg-no-repeat;
  }
}

.searchbar{
  transition:all 0.2s ease;
  @apply border border-lightGrey rounded-full h-12 md:h-16 flex justify-between items-center bg-white;

  @media (max-width:768px){
    &.searchbar-mobile {
      @apply flex;
    }

    @apply hidden
  }

  .separator{
    height: 45px;
    width: 1px;
    @apply bg-darkBlue;
  }

  .submit-btn{
    &:hover{
      background-size: 120%;
    }
  }

  .notaire-type-div{
    @apply w-1/2;

    input{
      width: calc(100% - 16px);
    }
  }

  .city-choice-div, .article-choice-div {
    @apply w-full flex justify-between items-center;

    .city-choice, .article-choice{
      width: calc(100% - 16px - 16px - 16px - 24px - 40px - 16px);
    }

    .geoloc-icon{
      @apply mr-4
    }
  }

  &.-double{
    width: 900px;

    .single-notaire-type-choice{
      @apply hidden;
    }

    @media (max-width:1280px){
      width: 600px;
    }
    @media (max-width:992px){
      width: 450px;
    }
  }

  &.-single{
    width: 450px;
    @apply cursor-pointer;

    @media (max-width:767px){
      width: 100%;
    }

    .single-notaire-type-choice{
      @apply inline-block;
    }

    .notaire-type-div{
      @apply w-full;

      input{
        width: calc(100% - 16px - 16px - 24px);
        @apply pointer-events-none cursor-pointer
      }
    }

    .city-choice-div{
      width: auto;
    }

    .notaire-type-choice,.separator,.city-choice,.geoloc-icon{
      @apply hidden;
    }

    .submit-btn{
      @apply pointer-events-none cursor-pointer
    }
  }

//-------- MOBILE searchbar
  &.-mobile{
    @apply hidden mt-5;
    @media (max-width:768px){
      @apply flex
    }
  }
}

//-------- MOBILE PopUp searchbar
.js-searchbar-popup{
  transition:all 0.2s ease;
  top:-300px;
  @apply fixed z-50 bg-white w-full h-52 border-b border-lightGrey p-5;

  &.show{
    top:0;
  }

  .upper-section{
    @apply flex justify-between items-center mb-6;

    .close{
      @apply w-8 hover:opacity-70;
    }
  }

  .js-searchbar-mobile{
    @apply  rounded-md overflow-hidden;
  }

  .input-fields-div{
    @apply flex justify-center;

    input{
      @apply border border-lightGrey w-1/2 border-b-0;
    }

    input:first-child{
      @apply border-r-0 rounded-tl-md;
    }
    input:last-child{
      @apply rounded-tr-md;
    }
  }

  .submit-btn{
    background-size: 30px;
    background-position-x:10px;
    @apply w-full bg-darkBlue text-white cursor-pointer;

  }

  input{
      @apply text-center h-11 m-0;
  }
}
