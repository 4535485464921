@mixin generic-button($bg_color, $text_color) {
  @apply py-3 px-8;
  @apply block w-fit;
  background-color: $bg_color;
  color: $text_color;
  transition:all 0.2s ease;
  @apply text-xs md:text-sm text-center font-semibold;

  &:hover {
    @apply tracking-wider;
  }
}

@mixin secondary-button($bg_color, $border_color, $text_color) {
  @apply py-3 px-8;
  @apply block w-fit;
  transition:all 0.2s ease;
  background-color: $bg_color;
  color: $text_color;
  border-color: $border_color;
  @apply text-xs md:text-sm text-center font-semibold;
  @apply border;

  &:hover {
    @apply px-9;
    @apply tracking-wider;
  }
}

.generic-button--transparent {
  @include generic-button(rgba(255, 255, 255, 0), #ffffff);
  @apply border-2 border-white
}

.generic-button--white-blue {
  @include generic-button(#fff, #002B53);
}

.generic-button--white-black {
  @include generic-button(#fff, #272727);
}

.generic-button--dark-blue {
  @include generic-button(#002B53, #fff);
}

.generic-button--orange {
  @include generic-button(#D95300, #fff);
}

.generic-button--light-blue {
  @include generic-button(#C9E0F5, #002B53);
}

.secondary-button--dark-blue {
  @include secondary-button(#fff, #002B53, #002B53);
}

.secondary-button--transparent-white {
  @include secondary-button(transparent, #fff, #fff);
}

