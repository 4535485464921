.appel-doffres {
    
    &__container {
        @apply flex flex-col w-full md:pl-11 md:p-6;
    }

    &__tender {
        @apply bg-white my-12 rounded-2xl py-5 px-9;

        .tender-reply-btn {
            @apply flex justify-between;
        }
        h4 {
            @apply text-orange;
        }

        p {
            @apply my-1;
        }
    }

    .tender-modal {
        width: 100%;
        border: 1px solid #ccc;
        height: 100px;
        min-width:200px;
        @apply p-2;
    }

    .publish-tender-modal-content  {
        width:700px;
        font-family: 'Poppins';
    }
}