.mosaic-component {
    &__container {
        @apply mt-16;

        h4 {
            @apply pb-5 border-b border-grey;
        }

        &__grid {
            @apply grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 mt-10 gap-16;

            &__card {
                @apply border border-lightGrey bg-white rounded-lg py-6 px-3 flex flex-col justify-between;

                .element-name {
                    @apply font-bold text-center break-words;
                }

                .element-type {
                    @apply mx-auto text-5xl text-darkBlue self-center my-7;
                }
            }
        }
    }
}