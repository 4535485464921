.comites-expertises-page {

    &__tabs{
        @apply mb-8;
    }

    .tabs {
        display:flex;
    }
    &__filters{
        @apply flex flex-col sm:flex-row justify-between items-start sm:items-center;
    }
    

    .small-text {
        font-size:0.75rem;
    }
    .wiper-slide {
        .title {
            max-width: 100%;
        }
    }
    .domains-arrows {
        width:30px;
        height:30px;
    }

    .arrow-containers {
        display:flex;
        cursor: pointer;
        justify-content: flex-end;
    }
}
