body {
  font-family: 'ITC';
  @apply font-medium;
}

.container{
  @apply mx-auto px-2 md:px-0;

  &.-small{
    @apply max-w-2xl;
  }

  .-medium{
    @apply max-w-7xl;
  }
}

.inner-div {  
  @apply pl-4 lg:pl-14 pr-4 mt-6 lg:mt-0;


}

h1 {
  font-size: 24px;
  line-height: 36px;
  @apply text-darkBlue font-semibold;

  @screen md {
    font-size: 40px;
    line-height: 98px;
  }
}

h2 {
  font-size: 20px;
  line-height: 32px;
  @apply text-darkBlue font-semibold;

  @screen md {
    font-size: 34px;
    line-height: 44px;
  }
}

h3 {
  font-family: 'Poppins';
  font-size: 18px;
  line-height: 28px;
  @apply text-darkBlue font-semibold;

  @screen md {
    font-size: 24px;
    line-height: 40px;
  }
}

h4 {
  font-family: 'Poppins';
  @apply text-base md:text-xl text-darkBlue font-bold;
}

h5 {
  font-family: 'Poppins';
  @apply text-xs md:text-base text-darkBlue font-bold;
  margin: 2em 0 1em;
}

p, li {
  font-family: 'Poppins';
  @apply text-xs md:text-base;
  @apply font-normal text-darkBlue;
}

a, button {
  font-family: 'Poppins';
  @apply font-semibold;
}
