.fournisseurs-page {
    &__container {
        h2 {
            @apply mb-14
        }

        &__logos {
            @apply grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 justify-center items-center my-10;

            img {
                width: auto;
                height: auto;
                @apply mx-auto
            }
        }
    }
}