@font-face {
  font-family: 'ITC';
  font-weight: 900;
  src: url(../../fonts/itc/itc-berkeley-oldstyle-std-black.otf) format('otf');
}

@font-face {
  font-family: 'Poppins';
  font-weight: 400;
  display: swap;
  src: url(../../fonts/poppins/Poppins-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-weight: 500;
  display: swap;
  src: url(../../fonts/poppins/Poppins-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-weight: 600;
  src: url(../../fonts/poppins/Poppins-SemiBold.ttf) format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-weight: 700;
  src: url(../../fonts/poppins/Poppins-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-weight: 900;
  src: url(../../fonts/poppins/Poppins-Black.ttf) format('truetype');
}



