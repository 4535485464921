.header {
    @apply flex justify-end;
    @apply border-t-8 border-orange;
    @apply fixed w-full h-24 top-0 bg-white z-20;

    &__link {
        @apply hidden lg:block container py-7;
        @apply text-darkerGrey font-bold text-right;

        @media (max-width: 1280px) {
            max-width:unset !important;
            @apply px-3;
        }

        &:hover {
            @apply underline;
        }
    }

    &__mobile {
        @apply block lg:hidden container mb-10;

        max-width: 95%;

        &__menu {
            @apply flex justify-between items-center;
            @apply border-b-2 border-lighterGrey;
            @apply py-5;

            &__logo {
                @apply w-10;
            }

            &__mobile {
                svg {
                    @apply text-3xl text-darkBlue;
                }
            }
        }

        &__user {
            @apply flex flex-row justify-center gap-2 items-center;

            .user-info {
                @apply flex items-baseline gap-2;

                p {
                    @apply font-bold text-orange;
                }

                svg {
                    @apply text-lightGrey text-xs -rotate-90 self-end;
                }
            }
        }
    }


}


.connexion{
    .header__link { 
        @apply inline-block;
    }

    .header__mobile {
        @apply hidden bg-green-600;
    }

}