.login-page {

    &__background {
        @apply absolute w-full h-full flex pointer-events-none;

        &.connexion{
            @apply hidden md:flex
        }

        &__column1 {
            @apply flex-1 h-full bg-lighterBlue z-0;
        }

        &__column2 {
            @apply flex-1 h-full;
            &__container {
                @apply relative h-full overflow-hidden;

                img {
                    @apply absolute top-0 h-full;
                    transform: scale(1.5) rotate(-45deg);

                    &:first-child {
                        animation: leftRight 10s linear infinite;
                    }

                    &:nth-child(2) {
                        animation: rightLeft 10s linear infinite;
                    }

                    &:last-child {
                        animation: rightLeftSlower 10s linear infinite;
                    }
                }
            }
        }
    }

    &__columns {
        @apply flex flex-row container mt-24;

        &__content {
            @apply flex-1 md:mr-28 z-10 mt-4;

            img{width:90px;}

            h1, h2 {
                @apply mt-8;
            }

            input[type=text], input[type=password] {
                &:first-child {
                    @apply mt-20;
                }
                @apply w-full text-2xl py-2 px-7 mt-8;
                @apply border border-lightGrey rounded-md;
            }

            .error-message {
                @apply text-orange pt-4;
            }

            .remember-checkbox {
                @apply text-darkBlue font-bold;
                font-family: 'Poppins';

                input[type=checkbox] {
                    @apply mr-7 mt-10;
                }
            }

            input[type=submit] {
                @apply w-full py-3 my-10;
                @apply bg-darkBlue text-white text-center cursor-pointer;
                font-family: 'Poppins'
            }

            .password-link {
                @apply underline text-darkBlue text-lg font-medium;
            }
        }
        
        &__image {
            @apply md:flex-1;
        }
    }   
}

@keyframes leftRight {
    0% {
      transform: scale(1.5) rotate(-45deg) translateY(0px);
    }
    50% {
        transform: scale(1.5) rotate(-45deg) translateY(-40px);
    }
    100% {
      transform: scale(1.5) rotate(-45deg) translateY(0px);
    }
}

@keyframes rightLeft {
    0% {
      transform: scale(1.5) rotate(-45deg) translateY(0px);
    }
    50% {
        transform: scale(1.5) rotate(-45deg) translateY(40px);
    }
    100% {
      transform: scale(1.5) rotate(-45deg) translateY(0px);
    }
}

@keyframes rightLeftSlower {
    0% {
      transform: scale(1.5) rotate(-45deg) translateY(0px);
    }
    20% {
        transform: scale(1.5) rotate(-45deg) translateY(-40px);
    }
    60% {
        transform: scale(1.5) rotate(-45deg) translateY(0px);
    }
    80%{
        transform: scale(1.5) rotate(-45deg) translateY(40px);
    }
    100% {
      transform: scale(1.5) rotate(-45deg) translateY(0px);
    }
}