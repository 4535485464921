.publications {
    &__grid {
        @apply mb-60;
        @apply grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-10;

        .publication {
            @apply bg-white border border-lightGrey relative;
            @apply w-fit mx-auto;
           

            &:hover {
                .content__description, .content__button {
                    @apply h-full opacity-100;
                }

                .content__description {
                    @apply py-3;
                }

                .content__button {
                    @apply py-3 px-10;
                }
            }

            .content {
                @apply absolute bottom-0 w-full bg-white p-2;
                

                &__title {
                    @apply font-bold;
                }

                &__description {
                    @apply h-0 opacity-0;
                    transition: all .3s ease-in-out;

                    * {
                        @apply text-grey;
                    }
                }

                &__button {
                    @apply mx-auto;
                    @apply h-0 opacity-0 p-0;
                    transition: all .3s ease-in-out;
                }
            }
        }
    }

}