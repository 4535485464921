.list-members-component {
    &__container {
        @apply mt-16;

        h4 {
            @apply pb-5 border-b border-grey;
        }
        
        &__tabs {
            @apply hidden md:grid grid-cols-12 mt-10 gap-16;
            @apply border-b border-lightGrey pb-5;

            p.bigger {
                @apply col-span-5;
            }
            p.bigger:last-child {
                @apply col-span-3;
            }

            p {
                @apply text-lightGrey font-semibold col-span-2;
            }
        }

       

        &__content {
            @apply grid grid-cols-12 mt-10 gap-5 font-bold;
            @apply bg-white md:bg-transparent  p-5 md:p-0;
          

            p.bigger {
                @apply col-span-6 md:col-span-5 text-xs xl:text-base;
            }

            p {
                @apply font-bold text-xs xl:text-base;
                @apply col-span-6 md:col-span-2;
            }

            .contact-info{
                @apply flex justify-end;

                &.bigger{
                    @apply col-span-6 md:col-span-2 text-sm xl:text-base;
                }

                a{
                    @apply text-darkBlue text-sm xl:text-base;
                    &:hover {
                        @apply underline;
                    }
                }

                .phone{
                    @apply font-normal;
                }

                .mail-icon {
                    @apply border-l border-black pl-3 ml-3;
                }
            }
        }
    }

    
}

.list-component__container__list{
    min-height: 500px;
    @apply flex flex-col justify-between;
}