.question-page {

  &__title{
    @apply mb-14;
  }

  &__text{
    @apply bg-white rounded-lg py-5 px-9;
  }

  &__social-media{
    @apply my-10 flex;

    .social-button{
      @apply ml-3;

      img {
        transition:all 0.2s ease;
        @apply scale-100;
      }

      &:hover{
        img{
          @apply scale-110;
        }
      }
    }
  }

  .link-card{
    @apply mb-14;

    h3 {
      @apply relative mb-1 text-lg ml-3;

      &::before {
        content:'\2022';
        top:0px;
        left:-15px;
        @apply absolute text-darkBlue text-xl font-bold inline-block w-4;
      }
    }

    a {
      @apply underline text-base font-normal;
    }
  }

}
