.menu-mobile {
    @apply w-11/12 block md:hidden;

    ul {
        @apply mt-8;

        li {
            @apply flex items-center gap-4;
            @apply py-3 px-5;
            
            a {
                @apply text-lg font-normal;
            }

            &.active {
                @apply bg-lightBlue rounded-lg;

                a {
                    @apply text-lg font-bold;
                }
            }

            &.logout {
                @apply border-t border-lighterGrey;
            }
        }
    }
}