.espace-collab-page {

    &__tabs{
        @apply mb-8;
    }

    &__filters{
        @apply flex flex-col sm:flex-row justify-between items-start sm:items-center;
    }
}
