.breadcrumbs {
    @apply hidden md:block;
    
    a {
        @apply text-blue font-normal;

        &:hover {
            .title {
                @apply underline;
            }
        }
    }
}