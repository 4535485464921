.single-info {
    &__container {
        @apply relative;

        .research-container {
            @apply flex justify-start items-center gap-10 mt-20;

            a svg {
                @apply text-5xl text-darkBlue;
            }
        }
    }

    &__content {
        @apply flex flex-col mb-24;
    }
}