.display-style {
    @apply absolute right-0 hidden md:flex items-center gap-2 justify-end mt-16 cursor-pointer;

    svg {
        @apply text-darkGrey;
    }

    p {
        @apply font-bold text-darkGrey;
    }
}