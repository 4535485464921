.folder-tab {
  @apply my-12;

  .years-tabs{
    @apply flex pb-5 border-b border-black w-fit;

    .year-button{
      @apply flex flex-col justify-center items-center mr-10 opacity-20;

      &.active{
        @apply opacity-100;
      }

      .year{
        @apply font-semibold text-base md:text-2xl;
      }
    }
  }
  
  .years-pdf{
    @apply mt-14 flex flex-wrap gap-5;

    .pdf {
      transition:all 0.2s ease;
      @apply p-4 mr-5;
      @apply border border-lightGrey rounded-md bg-white;
      @apply text-darkBlue uppercase hover:text-orange;
    }
   }
}