//------ popup styling

#pop-up-background{
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    z-index: 50;
    background-color: rgba(0,0,0, 0.5);
    justify-content: center;
    align-items: center;

    display: none;

    &.active{
        display: flex;
    }

    #pop-up{
        background-color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 2rem;
        padding: 2rem;
        p{
            font-size: 1.2rem;
            font-weight: 600;
        }
        .pop-up-button-container{
            display: flex;
            flex-direction: row;
            gap: 2rem;
        }
    }
}