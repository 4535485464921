.accueil-page{

  &__title{
    @apply mb-14;
  }

  &__fast-access{
    @apply mb-7;

    h2 {
      @apply mb-5;
    }

    .fast-access-flex{
      @apply flex justify-between flex-col gap-3;


      @media (min-width: 380px) {
        @apply flex flex-row justify-between flex-wrap gap-5
       }      

      .fast-access-card {
        flex: 1;
        transition: all 0.2s ease;
        @apply bg-white flex flex-col justify-center items-center w-full h-36 md:h-52;
        @apply p-5 text-center rounded-lg scale-100;

        h4 {
          @apply text-orange mb-5;
        }

        svg {
          @apply h-10 text-darkBlue;
        }

        &:hover{
          @apply scale-105;
        }

      }
    }
  }

  &__important-message{
    @apply bg-white rounded-lg py-5 px-9 mb-12;

    h2{
      @apply mb-5;
    }

    h3{
      @apply text-orange mb-5;
    }

    .message-list{
      @apply mt-3;

      ul {
        column-count: 2;
      }

      li {
        @apply relative mb-2 ml-3;
  
        &::before {
          content:'\2022';
          top:0px;
          left:-15px;
          @apply absolute text-darkBlue font-bold inline-block w-4;
        }

        a {
          @apply font-normal underline;
        }
      }
    }
  }

  &__verbal-proces{
    @apply bg-white rounded-lg py-5 px-9 mb-12;

    h2{
      @apply mb-9;
    }

    p{
      @apply mb-6;
    }

    a{
      min-width: 220px;
      @apply mb-3
    }
  }
}

