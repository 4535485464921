.menu {
    @apply bg-white z-10;
    @apply hidden lg:block;
    @apply overflow-y-scroll;
    height: 100%;

    &__logo {
        width:107px;
        @apply mt-5 ml-1;
    }

    h2 {
        @apply mt-5 ml-4;
    }

    p {
        @apply text-2xl font-bold text-orange mt-1 ml-4;
    }

    .user-info {
        @apply flex items-center gap-5;

        svg {
            @apply text-lightGrey text-xl -rotate-90 self-end;
        }
    }

    ul {
        @apply mt-5 w-11/12;

        li {
            
            a {
                width: 100%;
                height: 100%;
                @apply text-lg font-normal ;
                @apply flex items-center gap-4;
                @apply py-3 px-5;
            }

            &.active, &:hover {
                @apply bg-lightBlue rounded-lg;

                a {
                    @apply text-lg font-bold;
                }
            }

            &:hover:not(.active) {
                @apply bg-opacity-25;
            }


            &.logout {
                @apply border-t border-lighterGrey;
            }
        }
    }
}