@mixin arrow-link($border_color, $hover_border_color, $text_color, $hover_text_color) {
  @apply py-1 relative font-semibold;
  width: 300px;
  border-bottom: 2px solid $border_color;
  color: $text_color;
  transition: 0.5s;

  &:hover, &:active {
    border-bottom-color: $hover_border_color;
    color: $hover_text_color;

    &:before, &:after {
      content: "";
      right: 38%;
      border-width: 10px 20px;
      @apply w-0 h-0 absolute border-solid border-transparent;
    }

    &:before {
      border-top-color: $hover_border_color;
      bottom: -20px;
    }

    &:after {
      border-top-color: #fff;
      bottom: -18px;
    }
  }
}

.arrow-link--dark-blue {
  @include arrow-link(#C7C7C7, #D95300, #C7C7C7, #002B53);
}
