.filters {
  margin-top: 20px;
}

.small-select {
  width: 350px;
  display: inline-block;
  margin-right: 15px;
}

.form-toggle {
  @apply mr-7;
  @apply text-sm md:text-xl text-darkGrey;
  font-family: 'Poppins';

  >input:checked+span {
    @apply font-bold;
  }
}


.formWrapper {
  display: flex;
  width: 100%;
  justify-content: space-between;
  position: relative;

  fieldset {
    // margin: auto;
    width: 500px;
  }

  .numPoste{
    position:absolute;
    left:-3em;
  }

  .results {
    margin-bottom: 25px;

    .result {
      border-bottom: 1px dashed rgb(217, 83, 0);
    }

    .title {
      margin-bottom: 2px;
    }

    .result-details {
      font-size: 10px;
    }

    border-bottom: 1px dashed rgba(0, 0, 0, 0.2);

  }

  .sidebar {
    max-width: 500px;

    nav li {
      list-style-type: none;
      font-size: 14px;
      @apply px-3 py-3;
      cursor: pointer;

      &.active {
        background: rgb(201, 224, 245);
        font-weight: bold;
      }
    }
  }
}

.employee-form {
  width: 100%;
}

.formRow {
  padding-bottom: 10px;

  label {
    width: 300px;
    display: inline-block;
  }

  input {
    padding: 10px;
    border-radius: 5px;
    width: 350px;
    border: 1px solid rgba(0, 0, 0, 0.5);
  }
}


form {

  .min-max-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .row-title {
      display: block;
      width: 100%;
    }

    .row {
      width: 45%;
      display: inline-block;
    }

    label {
      font-size: 12px;
    }
  }

  .number-fields-only {

    .row {
      display: flex;
      justify-content: space-between;
      width: 100%;


      label {
        display: inline-block;
        width: 60%;
      }

      input {
        max-height: 40px !important;
        width: 30%;
      }

    }

  }


  .fieldsRow {
    border: 1px solid rgba(200, 200, 200, 0.4);
    padding: 10px;
    margin-bottom: 10px;
  }

  .field-group {
    padding-bottom: 40px;
  }

  .ck-content {
    height: auto;
    max-width: 800px;
  }

  .pictures-wrapper {
    display: flex;
    margin: 15px 0;

    div {
      display: inline-block;
      position: relative;

      .picture {
        @apply mx-2;
        max-width: 200px;
      }

      .delete-icon {
        position: absolute;
        top: 0;
        color: red;
        background: rgba(100, 100, 100, 0.3);
        font-weight: bold;
        padding: 5px;
        right: 10px;
        cursor: pointer;
      }
    }
  }

  h3 {
    @apply mb-16;
    font-family: 'ITC';
  }

  fieldset {

    legend {
      font-size: 22px;
      font-weight: bold;
      font-family: 'ITC';
      margin: 1rem 0;
    }

    .note {
      font-size: 11px;
      margin-top: -15px;
      margin-bottom: 20px;
      font-family: "Poppins";
    }

    .btnrow {
      display: flex;
      justify-content: space-between;
    }

    .next,
    .back {
      cursor: pointer;
      @apply generic-button--dark-blue flex items-center justify-between gap-5;

      &:hover {
        background: rgb(217, 83, 0);
      }
    }

    @apply flex flex-col w-4/5;

    .title-orange {
      @apply text-orange my-10;
      font-family: 'ITC';
    }

    label,
    textarea {
      @apply pt-2 mb-2;
      @apply rounded-md;
      font-family: 'Poppins';
    }

    input {
      @apply w-full outline-none pb-3 pt-2 pl-2 pr-5 mb-5;
      @apply border border-lightGrey rounded-md bg-white;
      font-family: 'Poppins';
    }

    .chk-section {
      @apply my-4 md:my-8;
    }

    .select-section {
      @apply my-4 md:my-8;
    }

    .chk-wrapper {
      @apply flex flex-col md:flex-row my-4 gap-4 md:gap-16;

      .chk-col:first-child {
        @apply text-darkBlue text-xl;
        font-family: 'Poppins';
      }

      .chk-col {
        @apply block;

        label {
          @apply bg-transparent;
        }
      }

      label {
        @apply border-0 pl-3 pr-10 text-darkerGrey;
      }
    }

    select {
      @apply w-full;
      @apply border border-lightGrey rounded-md;
      @apply py-2 px-7 mt-2 md:mt-5 mb-1 md:mb-2;
      @apply text-grey;
      font-family: 'Poppins';
    }

    .files {
      @apply flex flex-row gap-10 mt-8;

      span {
        @apply self-center;
        @apply text-darkBlue text-xl;
        font-family: 'Poppins';
      }

      label {
        @apply w-fit cursor-pointer;
        @apply bg-transparent border border-orange;
        @apply text-orange text-base font-normal;

        input {
          @apply hidden;
        }
      }
    }

    input[type=submit] {
      @apply mt-5 px-9 py-3 w-fit mx-auto;
      @apply border border-darkBlue;
      @apply text-darkBlue font-semibold;
      @apply cursor-pointer;
    }

    input[type='checkbox'],
    input[type='radio'] {
      display: inline-block;
      width: 50px;
    }
  }
}

.data-choices {

  label,
  input {
    font-weight: normal;
    display: block;
    margin-bottom: 5px;
  }
}

.ratio-office-select-wrapper {
  margin-top: 10px;
}

.office-select {
  margin-top: 1.25rem;
  margin-bottom: 0.5rem;
}

.photo-section {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}

.picture-wrapper {
  display: inline-block;
  max-width: 50%;
}


#office-edit-form {
  fieldset {
    padding: 10px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    margin-bottom: 10px;
  }
}



input.invalid,
div.invalid {
  border: 1px solid red;
}


.results .title {
  width: 100%;
}

input[type='date'].small {
  width: 80%;
}

.ratio-admin-wrpr {
  .nav {
    display: flex;
    gap: 1rem;

    div {
      display: inline-block;
      cursor: pointer;

      &.active {
        color: rgb(217, 83, 0);
      }
    }
  }
}

.centered-data {
  td {
    text-align: center;
    vertical-align: middle;
  }
}