.domains-tabs {
    @apply overflow-y-scroll;
    @apply mt-8;

    @apply flex;


    .tab {
        margin-right:27px;
        @apply text-sm md:text-lg cursor-pointer flex gap-2;
        @apply border border-darkBlue rounded-lg p-3 md:p-5;
        @apply text-darkBlue;
        font-family: 'Poppins';

        .count {
            @apply px-2 text-sm bg-darkBlue text-white rounded-full self-center;
        }

        .title {
            @apply whitespace-nowrap;
        }

        &.active {
            @apply  text-orange border-orange;
        }
    }
}