.pagination{
  @apply flex justify-center mt-10 mb-5;

  &.disabled{
    @apply hidden;
  }

  .page-item{ transition: all 0.2s ease;
    @apply mx-2 scale-100;

    &:hover{
      @apply scale-110;
    }

    .page-link{
      @apply text-2xl font-bold cursor-pointer;
    }


    &.active{
      .page-link{
        @apply text-orange cursor-pointer;
      }
    }
  }
}
