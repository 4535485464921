.tool-tab {
    @apply my-12;

    h3 {
        @apply mb-14 pb-5 border-b border-grey;
    }

    &__list {
        @apply mt-8
    }

    .tool-flex {
        @apply flex flex-col md:flex-row gap-2 w-full justify-between mb-10;

        .generic-button--dark-blue {
            width: 100%;

            @screen md {
                width: auto;
                min-width: 200px; 
            }
        }

        .tool-infos {
            .tool-title {
                @apply relative flex items-center mb-1 ml-3;
        
                &::before {
                    content:'\2022';
                    top:0px;
                    left:-15px;
                    @apply text-darkBlue text-xl font-bold inline-block w-4;
                }
            }

            .tool-description {
                @apply text-darkGrey;
            }
        }
    }
}