.inner-page {
  .title{
    width: 80%;
    margin: auto;
    @apply mb-14;
  }
  font-family: 'Poppins';
}

.button {
  cursor:pointer;
  @apply generic-button--dark-blue flex items-center justify-between gap-5;
  &:hover {
    background:rgb(217,83,0);
  }
}
.action-row {
  width:80%;
  margin-bottom:20px;
  display:flex;

}
.old-ratio {
  .row {
    width: 100% !important;
    padding:10px;
    
  }
  div:nth-child(odd) {
    background:rgba(200, 200, 200, 0.4);
  }
  div:nth-child(even) {
    background:rgba(100, 100, 100, 0.4);
  }
  
}

.old-ratio-list {
  thead th {
    position: sticky;
    top: 0;
    text-align:left;
    background:rgba(150, 150, 150, 0.4);
  }
  width: 1000px;
  height:800px;
  overflow:scroll;

  tr:nth-child(odd) {
      background:rgba(150, 150, 150, 0.4);
  }
  tr:nth-child(even) {
      background:rgba(200, 200, 200, 0.4);
  }
  tr {
    &:hover {
      background:rgba(217, 83, 0, 0.7);
    }
    td {
      border:1px solid #ccc;
    }
  }

  .key-name {
    font-size:12px;
  }
  .value-only {
    padding:10px;
    font-size:12px;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: right;
  }
}
.pointer {
  cursor: pointer;
}
.key-key {
  width:400px;
  display:inline-block;
}
.header-row{
  position:fixed;
  width:960px;
  .page-index {
    right:0;
    position:absolute;
  }
}


.employees-table {
  width:100%;
}

.employees-table td {
  font-size:14px;
  padding: 5px;
}
.employees-table tr:nth-child(even) {
  background:rgba(200,200,200,0.2);
}
.employees-table thead {
  background:rgb(245, 249, 254);
}
.employees-table thead td {
  font-size:20px;
}

.small-text {
  font-size:12px !important;
}
.employees-table thead td:last-child {
  padding-right: 4rem;
}

.employees-table .children-row {
  td:first-child {
    box-sizing: border-box;
    padding-left: 20px;
  }
  .employees-table thead td:last-child {
    background-color: red;
  }
}
.searchInput {
  border:1px solid #002B53;
  border-radius:5px;
  padding:10px;
  width:500px;
  margin-bottom:30px;
}

.row {
  width:50%;
  justify-content:space-between;

  .fields {
    width:100%;
  }
}

.button.delete {
  background-color:#c03434;
}