.list-component {
    &__container {
        @apply mt-16;

        h4 {
            @apply pb-5 border-b border-grey;
        }
        
        &__tabs {
            @apply hidden md:grid mt-10 gap-5;
            @apply border-b border-lightGrey pb-5;

            p.bigger {
                @apply col-span-2 md:col-span-3;
            }

           

            p {
                @apply text-lightGrey font-semibold;
            }
        }

        &__content {
            @apply grid mt-10 gap-3 md:gap-5 font-bold;
            @apply bg-white md:bg-transparent  p-5 md:p-0;




          

            &:hover p {
                @apply underline;
            }

            p.bigger {
                @apply text-base col-span-6 md:col-span-3  mb-3 md:mb-0;
            }

            p {
                @apply font-bold flex items-center;
                @apply col-span-2 md:col-span-1  text-sm xl:text-base;

                img{
                    width:15px;
                    filter: invert(42%) sepia(89%) saturate(4182%) hue-rotate(13deg) brightness(94%) ;
                    @apply mr-2 ; 
                }
               
            }
        }
    }
}

.list-component__container__list{

   
    min-height: 500px;
    @apply flex flex-col justify-between;
}
