.header-wrapper {
    @apply h-24;
}
    
.layout {

    .sidenav {
        margin: auto;
        width: 80%;
        text-align: center;
        ul {
            cursor:pointer;
            li {
                padding:10px;
                display:inline-block;
                border-bottom:2px solid rgb(0, 43, 83);
                margin-right:10px;
            }
            li.active {
                font-weight: bold;
                border-bottom:2px solid rgb(217, 83, 0);
            }
        }
    }

    .office-title {
        cursor:pointer;
    }

    .content {
        width:80%;
        margin: auto;
    }
    
    .notice {
        width: 80%;
        margin-left: auto;
        margin-right: auto;
    }
    
    @apply relative;

    .layout-container {
        @apply flex container bg-lighterBlue lg:bg-transparent;

        @media (max-width: 1280px) {
            max-width:unset !important;
        }

        .menu-wrapper {
            @apply w-0 md:w-1/4 bg-lighterBlue;

            @media (max-width: 1024px) { width:0% }
          
        }

        >div:nth-child(2) {
            @apply w-11/12 md:w-3/4 mx-auto;
            min-height: calc(100vh - 6rem);
            margin-bottom: 6rem;

            @media (max-width: 1024px) { width:100% }
        }

        .menu-mobile {
            @apply block lg:hidden;
        }
    }

    .background {
        @apply absolute h-full w-full hidden md:flex pointer-events-none -z-10;

        &__left {
            @apply bg-white w-1/4 h-full;
        }

        &__right {
            @apply bg-lighterBlue w-3/4 h-full;
        }
    }
}
